/**
 * @class ControlsStringAssets A collection of string assets that pertain to controls on UI forms
 */
export class ControlsStringAssets {

  static loginButtonText: string = "Sign In";
  static createNewAccountButtonText: string = "Create New Account";
  static getStartedTodayButtonText: string = "Get Started Today";
  static registerButtonText: string = "Register";
  static searchButtonText: string = "Search";
  static submitButtonText: string = "Submit";
  static automaticallySigninButtonText: string = "Automatically Sign-in";
  static proceedToSigninButtonText: string = "Proceed to Sign-in";
  static saveButtonText: string = "Save";
  static editeButtonText: string = "Edit";
  static deleteButtonText: string = "Delete";
  static confirmButtonText: string = "Confirm";
  static cancelButtonText: string = "Cancel";
  static okButtonText: string = "Ok";

  // File Drop Zone controls
  static fileDropZonePromptNotDragging: string = "Drag & drop a file here, or tap/click to choose a file";
  static fileDropZonePromptWhileDragging: string = "Drop files here...";

  // Digital Media tab control headings
  static digitalMediaTab_AudioClips: string = 'Audio Clips';
  static digitalMediaTab_Documents: string = 'Documents';
  static digitalMediaTab_Hyperlinks: string = 'Hyperlinks';
  static digitalMediaTab_Images: string = 'Images';
  static digitalMediaTab_Notes: string = 'Notes';
  static digitalMediaTab_SocialMedia: string = 'Social Media';
  static digitalMediaTab_Videos: string = 'Videos';

  // Description fields
  static descriptionRequired: string = 'Description is required';
  static descriptionInvalid: string = 'Description is invalid';
  static descriptionHelpInfo: string = 'A valid description is required';
  static descriptionLabel: string = 'Description';
  static descriptionPlaceholder: string = 'Enter description';

  static emailRequired: string = 'Email address is required';
  static emailInvalid: string = 'Email address is invalid';
  static emailHelpInfo: string = 'A valid email address is required (name@domain.ext)';
  static emailLabel: string = 'Email address';
  static emailPlaceholder: string = 'Enter email address';

  static emailOrPhoneNumberRequired: string = 'Email address or phone number is required';
  static emailOrPhoneNumberInvalid: string = 'Email address or phone number is invalid';
  static emailOrPhoneNumberHelpInfo: string = 'A valid email address or phone number is required';
  static emailOrPhoneNumberLabel: string = 'Email address or phone number';
  static emailOrPhoneNumberPlaceholder: string = 'Enter email address or phone number';

  static expungeEmailLabel: string = 'Email address of account to be expunged';

  static populateSearchMetadataDb: string = 'Email address of account to populate (optional)';

  static firstNameRequired: string = 'First name is required';
  static firstNameInvalid: string = 'First name is invalid';
  static firstNameHelpInfo: string = 'A valid first name is required';
  static firstNameLabel: string = 'First name';
  static firstNamePlaceholder: string = 'Enter first name';

  static hyperLinkUrlRequired: string = 'HyperLink URL is required';
  static hyperLinkUrlInvalid: string = 'HyperLink URL is invalid';
  static hyperLinkUrlHelpInfo: string = 'A valid HyperLink URL is required';
  static hyperLinkUrlLabel: string = 'HyperLink URL';
  static hyperLinkUrlPlaceholder: string = 'Enter HyperLink URL';

  static lastNameRequired: string = 'Last name is required';
  static lastNameInvalid: string = 'Last name is invalid';
  static lastNameHelpInfo: string = 'A valid last name is required';
  static lastNameLabel: string = 'Last name';
  static lastNamePlaceholder: string = 'Enter last name';

  static mobilePhoneRequired: string = 'Mobile phone number is required';
  static mobilePhoneInvalid: string = 'Mobile phone number is invalid';
  static mobilePhoneHelpInfo: string = 'A valid mobile phone number is required';
  static mobilePhoneLabel: string = 'Mobile phone number';
  static mobilePhonePlaceholder: string = 'Enter mobile phone number';

  static passwordRequired: string = 'Password is required';
  static confirmPasswordLabel: string = 'Confirm Password';
  static confirmPasswordRequired: string = 'Confirmation password is required';
  static confirmPasswordMustMatch: string = 'Confirmation password must match password';
  static passwordMinLength: string = 'Password must be at least 8 characters long';
  static passwordPattern: string = 'Password must contain both letters (a-z, A-Z) and digits (0-9), with at least 1 lowercase and 1 uppercase letter, and may optionally include any of these special characters: !@#$%&*';
  static passwordInvalid: string = 'Password is invalid';
  static passwordHelpInfo: string = 'Password: 8+ characters (lowercase, uppercase, & digits)';
  static passwordLabel: string = 'Password';
  static passwordPlaceholder: string = 'Enter password';

  static phoneNumberRequired: string = 'Phone number is required';
  static phoneNumberInvalid: string = 'Phone number is invalid';
  static phoneNumberHelpInfo: string = 'A valid phone number is required';
  static phoneNumberLabel: string = 'Phone number';
  static phoneNumberPlaceholder: string = 'Enter phone number';

  static preferredLocaleRequired: string = 'Preferred locale is required';
  static preferredLocaleInvalid: string = 'Preferred locale is invalid';
  static preferredLocaleHelpInfo: string = 'A valid preferred locale is required';
  static preferredLocaleLabel: string = 'Preferred locale';
  static preferredLocalePlaceholder: string = 'Enter preferred locale';

  static registrationCodeRequired: string = 'Registration Code is required';
  static registrationCodeInvalid: string = 'Registration Code is invalid';
  static registrationCodeHelpInfo: string = 'A valid registration code is required';
  static registrationCodeLabel: string = 'Registration Code';
  static registrationCodePlaceholder: string = 'Enter registration code';

  static termsAndConditionsLabel: string = 'I agree to the MyDigiBrain terms & conditions';

  // Backup
  static backupTransactionalDataLabel: string = 'Backup Transactional data';
  static backupDigitalMediaDataLabel: string = 'Backup Digital Media data';
  static backupSearchMetadataLabel: string = 'Backup Search Metadata';

  // AudioLinks
  static audioNew: string = 'New Audio Clip';

  static audioLinkAudioUrlRequired: string = 'Audio Url is required';
  static audioLinkAudioUrlInvalid: string = 'Audio Url is invalid';
  static audioLinkAudioUrlHelpInfo: string = 'A valid Audio Url is required';
  static audioLinkAudioUrlLabel: string = 'Audio Url';
  static audioLinkAudioUrlPlaceholder: string = 'Enter Audio Url';

  static audioLinkDescriptionRequired: string = 'Description is required';
  static audioLinkDescriptionInvalid: string = 'Description is invalid';
  static audioLinkDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static audioLinkDescriptionLabel: string = 'Description';
  static audioLinkDescriptionPlaceholder: string = 'Enter dscription';

  // Categories
  static categoryNew: string = 'New Category';

  static categoryNameRequired: string = 'Category name is required';
  static categoryNameInvalid: string = 'Category name is invalid';
  static categoryNameHelpInfo: string = 'A valid Category name is required';
  static categoryNameLabel: string = 'Name';
  static categoryNamePlaceholder: string = 'Enter Category name';

  static categoryDescriptionRequired: string = 'Description is required';
  static categoryDescriptionInvalid: string = 'Description is invalid';
  static categoryDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static categoryDescriptionLabel: string = 'Description';
  static categoryDescriptionPlaceholder: string = 'Enter dscription';

  // Channels
  static channelNew: string = 'New Channel';

  static channelNameRequired: string = 'Channel name is required';
  static channelNameInvalid: string = 'Channel name is invalid';
  static channelNameHelpInfo: string = 'A valid Channel name is required';
  static channelNameLabel: string = 'Name';
  static channelNamePlaceholder: string = 'Enter Channel name';

  static channelDescriptionRequired: string = 'Description is required';
  static channelDescriptionInvalid: string = 'Description is invalid';
  static channelDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static channelDescriptionLabel: string = 'Description';
  static channelDescriptionPlaceholder: string = 'Enter dscription';

  static channelTopicNameAliasSingularRequired: string = 'Topic Name Alias - Singular is required';
  static channelTopicNameAliasSingularInvalid: string = 'Topic Name Alias - Singular is invalid';
  static channelTopicNameAliasSingularHelpInfo: string = 'A valid Topic Name Alias - Singular is an alphanumeric string';
  static channelTopicNameAliasSingularLabel: string = 'Topic Name Alias - Singular';
  static channelTopicNameAliasSingularPlaceholder: string = 'Enter Topic Name Alias - Singular';

  static channelTopicNameAliasPluralRequired: string = 'Topic Name Alias - Plural is required';
  static channelTopicNameAliasPluralInvalid: string = 'Topic Name Alias - Plural is invalid';
  static channelTopicNameAliasPluralHelpInfo: string = 'A valid Topic Name Alias - Plural is an alphanumeric string';
  static channelTopicNameAliasPluralLabel: string = 'Topic Name Alias - Plural';
  static channelTopicNameAliasPluralPlaceholder: string = 'Enter Topic Name Alias - Plural';

  // Channel Sharing
  static channelSharingNew: string = 'New Share';

  static channelSharingRequest_Singular: string = 'Sharing Request';
  static channelSharingRequests_Plural: string = 'Sharing Requests';

  static channelSharingRecipientEmailRequired: string = 'A valid email address of a recipient user is required';
  static channelSharingRecipientEmailCantMatchCurrentUser: string = 'Recipient email address cannot be the same as the current user email';
  static channelSharingRecipientEmailInvalid: string = 'The recipient email is invalid';
  static channelSharingRecipientEmailHelpInfo: string = 'A valid recipient email is required';
  static channelSharingRecipientEmailLabel: string = 'Recipient Email';
  static channelSharingRecipientEmailPlaceholder: string = 'Enter recipient email';

  static channelSharingRecipientNameRequired: string = 'The name of a recipient user is required';
  static channelSharingRecipientNameInvalid: string = 'The recipient name is invalid';
  static channelSharingRecipientNameHelpInfo: string = 'A valid recipient name is required';
  static channelSharingRecipientNameLabel: string = 'Recipient Name';
  static channelSharingRecipientNamePlaceholder: string = 'Enter recipient name';

  static channelSharingUserEmailRequired: string = 'A valid email address of a sharing user is required';
  static channelSharingUserEmailCantMatchCurrentUser: string = 'Sharing user email address cannot be the same as the current user email';
  static channelSharingUserEmailInvalid: string = 'The sharing user email is invalid';
  static channelSharingUserEmailHelpInfo: string = 'A valid sharing user email is required';
  static channelSharingUserEmailLabel: string = 'Sharing Email';
  static channelSharingUserEmailPlaceholder: string = 'Enter sharing user email';

  static channelSharingUserNameRequired: string = 'The name of a sharing user is required';
  static channelSharingUserNameInvalid: string = 'The sharing user name is invalid';
  static channelSharingUserNameHelpInfo: string = 'A valid sharing user name is required';
  static channelSharingUserNameLabel: string = 'Sharing user Name';
  static channelSharingUserNamePlaceholder: string = 'Enter sharing user name';

  // DocumentLinks
  static documentNew: string = 'New Document';
  static documentNameRequired: string = 'Document name is required';
  static documentNameInvalid: string = 'Document name is invalid';
  static documentNameHelpInfo: string = 'A valid Document name is required';
  static documentNameLabel: string = 'Name';
  static documentNamePlaceholder: string = 'Enter Document name';

  static documentLinkDescriptionRequired: string = 'Description is required';
  static documentLinkDescriptionInvalid: string = 'Description is invalid';
  static documentLinkDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static documentLinkDescriptionLabel: string = 'Description';
  static documentLinkDescriptionPlaceholder: string = 'Enter dscription';

  static documentLinkDocumentUrlRequired: string = 'Document Url is required';
  static documentLinkDocumentUrlInvalid: string = 'Document Url is invalid';
  static documentLinkDocumentUrlHelpInfo: string = 'A valid Document Url is required';
  static documentLinkDocumentUrlLabel: string = 'Document Url';
  static documentLinkDocumentUrlPlaceholder: string = 'Enter Document Url';

  // Expunge User
  static expungeUserEmailCantMatchCurrentUser: string = 'The email address of the user to be expunged cannot be the same as the current user email';

  // HyperLinks
  static hyperLinkNew: string = 'New Hyperlink';

  static hyperLinkTitleRequired: string = 'Title is required';
  static hyperLinkTitleInvalid: string = 'Title is invalid';
  static hyperLinkTitleHelpInfo: string = 'A valid title is an alphanumeric string';
  static hyperLinkTitleLabel: string = 'Title';
  static hyperLinkTitlePlaceholder: string = 'Enter HyperLink title';

  static hyperLinkHyperLinkUrlRequired: string = 'HyperLink Url is required';
  static hyperLinkHyperLinkUrlInvalid: string = 'HyperLink Url is invalid';
  static hyperLinkHyperLinkUrlHelpInfo: string = 'A valid HyperLink Url is required';
  static hyperLinkHyperLinkUrlLabel: string = 'HyperLink Url';
  static hyperLinkHyperLinkUrlPlaceholder: string = 'Enter HyperLink Url';

  static hyperLinkDescriptionRequired: string = 'Description is required';
  static hyperLinkDescriptionInvalid: string = 'Description is invalid';
  static hyperLinkDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static hyperLinkDescriptionLabel: string = 'Description';
  static hyperLinkDescriptionPlaceholder: string = 'Enter dscription';

  // ImageLinks
  static imageNew: string = 'New Image';

  static imageLinkImageUrlRequired: string = 'Image Url is required';
  static imageLinkImageUrlInvalid: string = 'Image Url is invalid';
  static imageLinkImageUrlHelpInfo: string = 'A valid Image Url is required';
  static imageLinkImageUrlLabel: string = 'Image Url';
  static imageLinkImageUrlPlaceholder: string = 'Enter Image Url';

  static imageLinkDescriptionRequired: string = 'Description is required';
  static imageLinkDescriptionInvalid: string = 'Description is invalid';
  static imageLinkDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static imageLinkDescriptionLabel: string = 'Description';
  static imageLinkDescriptionPlaceholder: string = 'Enter dscription';

  // Notes
  static noteNew: string = 'New Note';

  static noteTitleRequired: string = 'Title is required';
  static noteTitleInvalid: string = 'Title is invalid';
  static noteTitleHelpInfo: string = 'A valid title is an alphanumeric string';
  static noteTitleLabel: string = 'Title';
  static noteTitlePlaceholder: string = 'Enter Note title';

  static noteNoteContentRequired: string = 'Note content is required';
  static noteNoteContentInvalid: string = 'Note content is invalid';
  static noteNoteContentHelpInfo: string = 'A valid note content is an alphanumeric string';
  static noteNoteContentLabel: string = 'Note content';
  static noteNoteContentPlaceholder: string = 'Enter note content';

  // Search Digital Media Criteria
  static digitalMediaSearchCriteriaSearchPhraseRequired: string = 'A search phrase is required';
  static digitalMediaSearchCriteriaSearchPhraseInvalid: string = 'Search phrase is invalid';
  static digitalMediaSearchCriteriaSearchPhraseHelpInfo: string = 'A valid search phrase is required';
  static digitalMediaSearchCriteriaSearchPhraseLabel: string = 'Search phrase';
  static digitalMediaSearchCriteriaSearchPhrasePlaceholder: string = 'Enter a search phrase';

  static digitalMediaSearchCriteriaSearchAllAccessibleChannelsLabel: string = 'All accessible channels';

  static digitalMediaSearchCriteriaIncludeAllDigitalMediaTypesLabel: string = 'All digital media types';

  // SocialMedia
  static socialMediaPostNew: string = 'New Post';

  static socialMediaPostUrlRequired: string = 'Social Media Post Url is required';
  static socialMediaPostUrlInvalid: string = 'Social Media Post Url is invalid';
  static socialMediaPostUrlHelpInfo: string = 'A valid Social Media Post Url is required';
  static socialMediaPostUrlLabel: string = 'Social Media Post Url';
  static socialMediaPostUrlPlaceholder: string = 'Enter Social Media Post Url';

  static socialMediaPostDescriptionRequired: string = 'Description is required';
  static socialMediaPostDescriptionInvalid: string = 'Description is invalid';
  static socialMediaPostDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static socialMediaPostDescriptionLabel: string = 'Description';
  static socialMediaPostDescriptionPlaceholder: string = 'Enter dscription';

  // Themes controls
  static themeControls_CustomThemes: string = 'Custom Themes';

  // Theme Specs
  static themeSpecsNew: string = 'New Theme';

  static themeSpecsNameRequired: string = 'Theme name is required';
  static themeSpecsNameInvalid: string = 'Theme name is invalid';
  static themeSpecsNameHelpInfo: string = 'A valid Theme name is required';
  static themeSpecsNameLabel: string = 'Name';
  static themeSpecsNamePlaceholder: string = 'Enter Theme name';

  static themeSpecsDescriptionRequired: string = 'Description is required';
  static themeSpecsDescriptionInvalid: string = 'Description is invalid';
  static themeSpecsDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static themeSpecsDescriptionLabel: string = 'Description';
  static themeSpecsDescriptionPlaceholder: string = 'Enter dscription';

  // Topics
  static topicNew: string = 'New Topic';

  static topicNameRequired: string = 'Topic name is required';
  static topicNameInvalid: string = 'Topic name is invalid';
  static topicNameHelpInfo: string = 'A valid topic name is required';
  static topicNameLabel: string = 'Name';
  static topicNamePlaceholder: string = 'Enter topic name';

  static topicNamePluralizedRequired: string = 'Topic name pluralized is required';
  static topicNamePluralizedInvalid: string = 'Topic name pluralized is invalid';
  static topicNamePluralizedHelpInfo: string = 'A valid topic name pluralized is required';
  static topicNamePluralizedLabel: string = 'Topic name pluralized';
  static topicNamePluralizedPlaceholder: string = 'Enter topic name pluralized';

  static topicDescriptionRequired: string = 'Description is required';
  static topicDescriptionInvalid: string = 'Description is invalid';
  static topicDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static topicDescriptionLabel: string = 'Description';
  static topicDescriptionPlaceholder: string = 'Enter dscription';

  static topicTopicItemNameAliasSingularRequired: string = 'Topic Item Name Alias - Singular is required';
  static topicTopicItemNameAliasSingularInvalid: string = 'Topic Item Name Alias - Singular is invalid';
  static topicTopicItemNameAliasSingularHelpInfo: string = 'A valid Topic Item Name Alias - Singular is an alphanumeric string';
  static topicTopicItemNameAliasSingularLabel: string = 'Topic Item Name Alias - Singular';
  static topicTopicItemNameAliasSingularPlaceholder: string = 'Enter Topic Item Name Alias - Singular';

  static topicTopicItemNameAliasPluralRequired: string = 'Topic Item Name Alias - Plural is required';
  static topicTopicItemNameAliasPluralInvalid: string = 'Topic Item Name Alias - Plural is invalid';
  static topicTopicItemNameAliasPluralHelpInfo: string = 'A valid Topic Item Name Alias - Plural is an alphanumeric string';
  static topicTopicItemNameAliasPluralLabel: string = 'Topic Item Name Alias - Plural';
  static topicTopicItemNameAliasPluralPlaceholder: string = 'Enter Topic Item Name Alias - Plural';

  // TopicItems
  static topicItemNew: string = 'New Topic Item';

  static topicItemNameRequired: string = 'Topic Item name is required';
  static topicItemNameInvalid: string = 'Topic Item name is invalid';
  static topicItemNameHelpInfo: string = 'A valid Topic Item name is required';
  static topicItemNameLabel: string = 'Name';
  static topicItemNamePlaceholder: string = 'Enter topic item name';

  static topicItemDescriptionRequired: string = 'Description is required';
  static topicItemDescriptionInvalid: string = 'Description is invalid';
  static topicItemDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static topicItemDescriptionLabel: string = 'Description';
  static topicItemDescriptionPlaceholder: string = 'Enter dscription';

  static topicItemOccurrenceTimestampRequired: string = 'Occurrence Timestamp is required';
  static topicItemOccurrenceTimestampInvalid: string = 'Occurrence Timestamp is invalid';
  static topicItemOccurrenceTimestampHelpInfo: string = 'A valid Occurrence Timestamp is a date and time';
  static topicItemOccurrenceTimestampLabel: string = 'Occurrence Date/Time';
  static topicItemOccurrenceTimestampPlaceholder: string = 'Enter occurrence timestamp';

  // VideoLinks
  static videoNew: string = 'New Video';

  static videoLinkDescriptionRequired: string = 'Description is required';
  static videoLinkDescriptionInvalid: string = 'Description is invalid';
  static videoLinkDescriptionHelpInfo: string = 'A valid description is an alphanumeric string';
  static videoLinkDescriptionLabel: string = 'Description';
  static videoLinkDescriptionPlaceholder: string = 'Enter dscription';

  static videoLinkVideoUrlRequired: string = 'Video Url is required';
  static videoLinkVideoUrlInvalid: string = 'Video Url is invalid';
  static videoLinkVideoUrlHelpInfo: string = 'A valid Video Url is required';
  static videoLinkVideoUrlLabel: string = 'Video Url';
  static videoLinkVideoUrlPlaceholder: string = 'Enter Video Url';

}