import { IRegistrationData, IRegistrationDataAsJson } from '.';
import { enumLocale, enumTheme } from '../../../enums';

/** 
 * @class RegisterData Represents data associated with a Registration operation
 */
export class RegistrationData implements IRegistrationData {
  /**
   * @method Constructor method
   * @param {string | undefined} email An email address (for authenticating by email/password)
   * @param {string | undefined} password A password (for authenticating by password)
   * @param {string | undefined} firstName User's first name
   * @param {string | undefined} lastName User's last name
   * @param {string | undefined} mobilePhone User's mobile phone
   * @param {string | undefined} theme User's preferred theme
   * @param {string | undefined} preferredLocale User's preferred locale
   * @param {string | undefined} registrationCode If applicable, a registration code (like a coupon) for the user account
   */
  constructor(
    email?: string | undefined,
    password?: string | undefined,
    firstName?: string | undefined,
    lastName?: string | undefined,
    mobilePhone?: string | undefined,
    theme?: string | undefined,
    preferredLocale?: string | undefined,
    registrationCode?: string | undefined
  ) {
    this._email = email;
    this._password = password;
    this._firstName = firstName;
    this._lastName = lastName;
    this._mobilePhone = mobilePhone;
    this._theme = theme;
    this._preferredLocale = preferredLocale;
    this._registrationCode = registrationCode;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _email An email address (for authenticating by email/password)
   */
  private _email: string | undefined = undefined;

  /**
   * @method email Getter method for _email
   */
  get email(): string | undefined {
    return this._email;
  }

  /**
   * @method email Setter method for _email
   * @param {string | undefined} value The value to be used in setting _email
   */
  set email(value: string | undefined) {
    this._email = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _password A password (for authenticating by password)
   */
  private _password: string | undefined = undefined;

  /**
   * @method password Getter method for _password
   */
  get password(): string | undefined {
    return this._password;
  }

  /**
   * @method password Setter method for _password
   * @param {string | undefined} value The value to be used in setting _password
   */
  set password(value: string | undefined) {
    this._password = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _firstName User's first name
   */
  private _firstName: string | undefined = undefined;

  /**
   * @method firstName Getter method for _firstName
   */
  get firstName(): string | undefined {
    return this._firstName;
  }

  /**
   * @method firstName Setter method for _firstName
   * @param {string | undefined} value The value to be used in setting _firstName
   */
  set firstName(value: string | undefined) {
    this._firstName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _lastName User's last name
   */
  private _lastName: string | undefined = undefined;

  /**
   * @method lastName Getter method for _lastName
   */
  get lastName(): string | undefined {
    return this._lastName;
  }

  /**
   * @method lastName Setter method for _lastName
   * @param {string | undefined} value The value to be used in setting _lastName
   */
  set lastName(value: string | undefined) {
    this._lastName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _mobilePhone User's mobile phone number
   */
  private _mobilePhone: string | undefined = undefined;

  /**
   * @method mobilePhone Getter method for _mobilePhone
   */
  get mobilePhone(): string | undefined {
    return this._mobilePhone;
  }

  /**
   * @method mobilePhone Setter method for _mobilePhone
   * @param {string | undefined} value The value to be used in setting _mobilePhone
   */
  set mobilePhone(value: string | undefined) {
    this._mobilePhone = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _theme User's preferred theme
   */
  private _theme: string | undefined = enumTheme.Default;

  /**
   * @method theme Getter method for _theme
   */
  get theme(): string | undefined {
    return this._theme;
  }

  /**
   * @method theme Setter method for _theme
   * @param {string | undefined} value The value to be used in setting _theme
   */
  set theme(value: string | undefined) {
    this._theme = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _preferredLocale User's preferred locale
   */
  private _preferredLocale: string | undefined = enumLocale.English;

  /**
   * @method preferredLocale Getter method for _preferredLocale
   */
  get preferredLocale(): string | undefined {
    return this._preferredLocale;
  }

  /**
   * @method preferredLocale Setter method for _preferredLocale
   * @param {string | undefined} value The value to be used in setting _preferredLocale
   */
  set preferredLocale(value: string | undefined) {
    this._preferredLocale = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _registrationCode If applicable, a registration code (like a coupon) for the user account
   */
  private _registrationCode: string | undefined = enumLocale.English;

  /**
   * @method registrationCode Getter method for _registrationCode
   */
  get registrationCode(): string | undefined {
    return this._registrationCode;
  }

  /**
   * @method registrationCode Setter method for _registrationCode
   * @param {string | undefined} value The value to be used in setting _registrationCode
   */
  set registrationCode(value: string | undefined) {
    this._registrationCode = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(): IRegistrationDataAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IRegistrationDataAsJson = {
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        mobilePhone: this.mobilePhone,
        preferredLocale: this.preferredLocale,
        registrationCode: this.registrationCode
      };

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/
}
