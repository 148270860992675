import { UserCredential } from 'firebase/auth';
import { take, put } from 'redux-saga/effects';
// import { actionTypeEnums, loginSuccess, loginFailure } from '../../actions';
import { loginWithEmailAndPassword } from '../../../firebaseServices/securityServices/userAccount/userAccountActions';
import { enumMdbErrorType } from '../../../errorObjects/enums';
import { MdbError } from '../../../errorObjects/MdbError';
import { loginSuccess, loginRequest } from '../../slices/login/loginDataSlice';
import { loginFailure } from '../../slices/login/loginFailureSlice';
import { ILoginData } from '../../../dataObjects/models/registerAndLogin/LoginData';
import { loginStatusChange } from '../../slices/login/loginStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { userRegistrationDataClear } from '../../slices/registration/userRegistrationDataSlice';

/**
 * @function loginRequestedSaga A saga workflow pertaining to a user having requested to login to the application with credentials.
 */
export function* loginRequestedSaga() {
  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c (loginRequestedSaga) Entered loginRequestedSaga', 'background: #B0B; color: #fff');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the loginRequest action to be dispatched
      const loginRequestData = yield take(loginRequest);
      const { payload: loginData } = loginRequestData;

      displayConsoleLogs && console.log(`%c (loginRequestedSaga) 'loginRequest' action detected.`, 'background: #B0B; color: #fff');

      // make a request to login
      const userAccount: UserCredential = yield loginWithEmailAndPassword(loginData.email!, loginData.password!);

      displayConsoleLogs && console.log(`%c (loginRequestedSaga) Returned from loginWithEmailAndPassword(). userAccount: ${JSON.stringify(userAccount)}`, 'background: #B0B; color: #fff');

      // if the login resulted in a valid user account, mask out the password, and then dispatch that the login was successful (and clear any user registration data)
      // passing the login data with the masked out password
      if (userAccount && userAccount.user && userAccount.user.uid) {
        const loginDataWithMaskedPassword: ILoginData = { ...loginData, _password: '********' /*, password: '********' */ };
        yield put(loginSuccess(loginDataWithMaskedPassword));

        // since the login is successful, we want to clear any user registration data from store state, in the case that just prior to the login the user had created a new account
        displayConsoleLogs && console.log(`%c (loginRequestedSaga) Ready to dispatch a call to userRegistrationDataClear().`, 'background: #B0B; color: #fff');
        yield (put(userRegistrationDataClear()));

        displayConsoleLogs && console.log(`%c (loginRequestedSaga) Login succeeded. userAccount: ${JSON.stringify(userAccount)}`, 'background: #B0B; color: #fff');
      } else {
        yield put(loginStatusChange(enumWorkflowState.Failure));
        yield put(loginFailure(new MdbError('Sign-in was unsuccessful. Reason unknown.', enumMdbErrorType.UnknownError)));

        displayConsoleLogs && console.log(`%c (loginRequestedSaga) Sign-in was unsuccessful`, 'background: #B0B; color: #fff');
      }
    } catch (error: any) {
      // dispatch an action to indicate that the login failed
      displayConsoleLogs && console.log(`%c (loginRequestedSaga) Caught an exception. error: ${JSON.stringify(error)}`, 'background: #B0B; color: #fff');
      yield put(loginStatusChange(enumWorkflowState.Failure));
      // yield put(loginFailure(error));
      yield put(loginFailure(error));
    }
  }
}
