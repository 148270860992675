import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { IRegistrationData, RegistrationData } from '../../../dataObjects/models/registerAndLogin/RegistrationData';
import RegistrationForm from '../../forms/RegistrationForm/RegistrationForm';
import { enumTheme, enumWorkflowState } from '../../../dataObjects/enums';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import { composeMessageUsingStringAsset } from '../../messages';
import { enumAlertType } from '../../enums';
import { AlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { useReduxWorkflowState } from '../../customHooks';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { IMdbError } from '../../../errorObjects/MdbError';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { userRegistrationRequest } from '../../../uiMiddleware-redux/slices/registration/userRegistrationDataSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall header
const StyledBoxForPaddingAtopPage = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export interface IRegistrationPageProps extends PropsWithChildren<unknown> {

}

export const RegistrationPage: React.FC<IRegistrationPageProps> = (props: IRegistrationPageProps) => {

  RegistrationPage.displayName = 'Registration Page';

  // prepare RegistrationData
  // const defaultRegistrationEmail: string | undefined = process.env.REACT_APP_DEFAULT_USER_ACCOUNT_EMAIL;
  // const defaultRegistrationPassword: string | undefined = process.env.REACT_APP_DEFAULT_USER_ACCOUNT_PASSWORD;
  // const defaultRegistrationFirstName: string | undefined = 'Tom';
  // const defaultRegistrationLastName: string | undefined = 'Kent';
  // const defaultRegistrationMobilePhone: string | undefined = '4088587257';

  const defaultRegistrationEmail: string | undefined = '';
  const defaultRegistrationPassword: string | undefined = '';
  const defaultRegistrationFirstName: string | undefined = '';
  const defaultRegistrationLastName: string | undefined = '';
  const defaultRegistrationMobilePhone: string | undefined = '';
  const defaultRegistrationDataTheme: string | undefined = enumTheme.Default;
  const defaultRegistrationPreferredLocale: string | undefined = 'en';
  const defaultRegistrationCode: string | undefined = '';
  const defaultRegistrationData: IRegistrationData = new RegistrationData(defaultRegistrationEmail, defaultRegistrationPassword, defaultRegistrationFirstName,
    defaultRegistrationLastName, defaultRegistrationMobilePhone, defaultRegistrationDataTheme, defaultRegistrationPreferredLocale, defaultRegistrationCode);


  const dispatch = useAppDispatch();

  // // alert information, in the case that an alert is to be displayed
  // const [alertInfo, setAlertInfo] = useState<IAlertInfo | undefined>(undefined);

  // the status of registration operation
  const [registrationStatus, setRegistrationStatus] = useState<enumWorkflowState | null | undefined>(null);

  // the failure error from registration operation
  const [registrationFailureError, setRegistrationFailureError] = useState<IMdbError | null | undefined>(null);

  // whether a registration operation is currently in progress
  const [registrationInProgress, setRegistrationInProgress] = useState<boolean>(false);


  // Check for the Registration workflowState and errorState values in Redux state. A valid registrationStatus indicates that the user is logging in.
  // The presence of a registrationFailure error indicates that there was a failure during registration.
  const { workflowState: userRegistrationStatus, errorState: userRegistrationFailure } = useReduxWorkflowState((state: IStoreState) => state.userRegistrationStatus, (state: IStoreState) => state.userRegistrationFailure);

  // if the registration status in Redux state is different from our local state, set our local state
  if (registrationStatus !== userRegistrationStatus) {
    setRegistrationStatus(userRegistrationStatus);
  }

  // if the registration failure error in Redux state is different from our local state, set our local state
  if (userRegistrationStatus === null && userRegistrationFailure !== null) {
    setRegistrationFailureError(null);
  } else if (registrationFailureError !== userRegistrationFailure) {
    setRegistrationFailureError(userRegistrationFailure);
  }

  /**
   * @function clearAlertInfoLocal Clears Alert information from Redux state
   */
  const clearAlertInfoLocal: () => void = useCallback((): void => {
    dispatch(alertInfoChange(null));
  }, [dispatch]);

  useEffect(() => {

    // cleanup upon unmounting component
    clearAlertInfoLocal();

  }, [clearAlertInfoLocal]);

  // Check for registration data (userRegistrationData). 
  const userRegistrationData: IRegistrationData | null | undefined = useAppSelector((state: IStoreState) => state.userRegistrationData);
  // console.info(`RegistrationPage. userRegistrationData in Redux store: ${JSON.stringify(userRegistrationData)}`);


  // function (memoized with the useCallback hook) to be called when processing a change to the registration operation status
  // NOTE: In the current design of the application, this function (processSuccessRegistrationStatus) will never be called within this page, because
  //       as soon as the user has been logged in, the React Router will route the user away from the Registration Page and to the Home Page.
  //       This function will be left in place to merely maintain consistency in representing all possible states during the registration process.
  const processSuccessRegistrationStatus = useCallback(
    () => {
      // set the registrationInProgress state to 'false' if it's not already in that state (don't want an extra render if not necessary)
      if (registrationInProgress) {
        setRegistrationInProgress(false);
      }

      // if userRegistrationData is available, extract the email address
      const userEmail: string = userRegistrationData ? (userRegistrationData.email ? userRegistrationData.email : '') : 'unknown email';

      const registrationSuccessNextSteps: string = composeMessageUsingStringAsset(MessagesStringAssets.registration_Success, userEmail, MessagesStringAssets.substitutionKeyword);
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      dispatch(alertInfoChange(new AlertInfo(true, enumAlertType.Success, registrationSuccessNextSteps)));

      // NOTE: We won't reset the UserRegistrationStatus from this component. Once the new user logs in, the UserRegistrationStatus will be reset.
    },
    [dispatch, registrationInProgress, userRegistrationData],
  )

  const processInProgressRegistrationStatus = useCallback(
    () => {
      // set the registrationInProgress state to 'true' if it's not already in that state (don't want an extra render if not necessary)
      if (!registrationInProgress) {
        setRegistrationInProgress(true);
      }
    },
    [registrationInProgress],
  )

  const processFailureRegistrationStatus = useCallback(
    () => {
      const registrationFailureMessage: string = composeMessageUsingStringAsset(MessagesStringAssets.registration_Failure, registrationFailureError ? registrationFailureError.message : '', MessagesStringAssets.substitutionKeyword);
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      dispatch(alertInfoChange(new AlertInfo(true, enumAlertType.Error, registrationFailureMessage)));

      // set the registrationInProgress state to 'false' if it's not already in that state (don't want an extra render if not necessary)
      if (registrationInProgress) {
        setRegistrationInProgress(false);
      }
    },
    [dispatch, registrationInProgress, registrationFailureError],
  )

  const processPerRegistrationStatus = useCallback(
    () => {
      // console.info(`RegistrationPage.processPerRegistrationStatus(). registrationStatus=${registrationStatus}`);

      if (registrationStatus) {
        switch (registrationStatus) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
            processInProgressRegistrationStatus();
            break;

          case enumWorkflowState.Success:
            processSuccessRegistrationStatus();
            break;

          case enumWorkflowState.Failure:
            processFailureRegistrationStatus();
            break;
        }
      }
    },
    [registrationStatus, processInProgressRegistrationStatus, processSuccessRegistrationStatus, processFailureRegistrationStatus],
  )

  // whenever the 'registrationStatus' value changes, perform processing per that value
  useEffect(() => {
    processPerRegistrationStatus();
  }, [registrationStatus, processPerRegistrationStatus]);


  /**
   * @method handleRegistrationFormSubmit Handles a submit request from the RegistrationForm
   * @param event A Submit event
   */
  function handleRegistrationFormSubmit(registrationData: IRegistrationData): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch an action to request a user registration
        dispatch(userRegistrationRequest(registrationData));

        setRegistrationInProgress(true);

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }


  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={false}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_UserRegistration}
      >
        <RegistrationForm
          registrationData={defaultRegistrationData}
          registrationRequestInProgress={registrationInProgress}
          onSubmit={handleRegistrationFormSubmit}
        />

      </GenericPageContainer>
    </>

  );

} // const RegistrationPage...

export default RegistrationPage;
