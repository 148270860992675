/**
 * @class RegularExpressions A collection of Regular Expressions (RegEx or RegExp)
 */
export class RegularExpressions {
  // RegEx for a Generic Url
  // static GenericUrl: RegExp = /(https?\:\/\/)?(www.)?(.+)\.(.+)(\/.+)/i;
  // static GenericUrl: RegExp = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/i;
  // static GenericUrl: RegExp = /.+/i;
  static GenericUrl: RegExp = /^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/i;

  // RegEx for a Password
  static Password: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%&*]{8,}$/;

  // RegEx for a Phone Number
  static PhoneNumber: RegExp = /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/;

  // RegEx for a valid audio file extension
  static ValidAudioUrl: RegExp = /(m4a|mp3|mp4|mpeg|oga|ogg|opus|wav|weba|webm)$/i;

  // RegEx for a valid image file extension
  static ValidImageUrl: RegExp = /(jpe?g|png|gif|bmp)$/i;

  // RegEx for external digital media websites
  static AppleMusic: RegExp = /(https?\:\/\/)?music\.apple\.com\/([^/]+\/[^/]+)/;
  static DailyMotion: RegExp = /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/;
  static Facebook: RegExp = /(https?\:\/\/)?(m\.|vm\.|www\.)?(facebook\.com|fb\.watch)(\/.+)/i;
  static Gab: RegExp = /(https?\:\/\/)?(?:www\.)?gab\.com\/.+\/posts\/.+/i;
  static Instagram: RegExp = /(https?\:\/\/)?(m\.|vm\.|www\.)?instagram\.com(\/.+)/i;
  static Kaltura: RegExp = /^https?:\/\/[a-zA-Z]+\.kaltura.(com|org)\/p\/([0-9]+)\/sp\/([0-9]+)00\/embedIframeJs\/uiconf_id\/([0-9]+)\/partner_id\/([0-9]+)(.*)entry_id.([a-zA-Z0-9-_]+)$/;
  static LinkedIn: RegExp = /(https?\:\/\/)?(m\.|vm\.|www\.)?linkedin\.com(\/.+)/i;
  static MixCloud: RegExp = /mixcloud\.com\/([^/]+\/[^/]+)/;
  static Pinterest: RegExp = /(https?\:\/\/)?(m\.|vm\.|www\.)?pinterest\.com(\/.+)/i;
  // static Rumble: RegExp = /rumble\.com\/([^/]+\/[^/]+)/;
  static Rumble: RegExp = /(?<=rumble.com\/).*?\b/g;
  static SoundCloud: RegExp = /(?:soundcloud\.com|snd\.sc)\/[^.]+$/;
  static Streamable: RegExp = /streamable\.com\/([a-z0-9]+)$/;
  // static Tiktok: RegExp = /^.*https:\/\/(?:m|www|vm)?\.?tiktok\.com\/((?:.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+))|\w+)/;
  // static Tiktok: RegExp = /(?:http(?:s):\/\/)?(?:m|www|vm)?\.?tiktok\.com\/*/;
  static Tiktok: RegExp = /(https?\:\/\/)?(m\.|vm\.|www\.)?tiktok\.com(\/.+)/i;
  static TruthSocial: RegExp = /(https?\:\/\/)?(?:www\.)?truthsocial\.com\/.+\/.+/i;
  static Twitch: RegExp = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
  // static TwitterOrX: RegExp = /(?:http(?:s):\/\/)?(?:www\.)?[twitter|x]\.com\/*/;
  static TwitterOrX: RegExp = /(https?\:\/\/)?(m\.|vm\.|www\.)?(twitter|x)\.com(\/.+)/i;
  static Vidyard: RegExp = /vidyard.com\/(?:watch\/)?([a-zA-Z0-9-_]+)/;
  static Vimeo: RegExp = /vimeo\.com\/(?!progressive_redirect).+/;
  static YouTube: RegExp = /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;

  /**
   * Regular Expressions for different forms of representing a color.
   *   Reference: https://stackoverflow.com/questions/12385500/regex-pattern-for-rgb-rgba-hsl-hsla-color-coding
   */
  // A color represented by a keyword (e.g., 'red', 'green', etc.)
  static ColorByKeyword: RegExp = /^[a-z]*$/;
  // A color represented by a hex code (e.g., '#FB0', '#FAB602' etc.)
  // static ColorByHexCode: RegExp = /^#[0-9a-f]{3}([0-9a-f]{3})?$/;
  static ColorByHexCode: RegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})?$/;
  // A color represented by RGB(r, g, b) (e.g., 'rgb(255, 127, 127)', 'RGB(55, 188, 22)' etc.)
  static ColorByRGBValues: RegExp = /^[Rr][Gg][Bb]\\(\\s*(0|[1-9]\\d?|1\\d\\d?|2[0-4]\\d|25[0-5])%?\\s*,\\s*(0|[1-9]\\d?|1\\d\\d?|2[0-4]\\d|25[0-5])%?\\s*,\\s*(0|[1-9]\\d?|1\\d\\d?|2[0-4]\\d|25[0-5])%?\\s*\\)$/;
  // A color represented by RGBA(r, g, b) (adding Alpha range) (e.g., 'rgb(255, 127, 127, 204)', 'RGB(55, 188, 22, 0.8)' etc.)
  static ColorByRGBAValues: RegExp = /^[Rr][Gg][Bb][Aa]\\(\\s*(0|[1-9]\\d?|1\\d\\d?|2[0-4]\\d|25[0-5])%?\\s*,\\s*(0|[1-9]\\d?|1\\d\\d?|2[0-4]\\d|25[0-5])%?\\s*,\\s*(0|[1-9]\\d?|1\\d\\d?|2[0-4]\\d|25[0-5])%?\\s*,\\s*((0.[0-9]*)|[01])\\s*\\)$/;
  // A color represented by HSL(h, s, l) (e.g., 'hsl(240, 100%, 50%)', 'HSL(55, 80%, 22%)' etc.)
  static ColorByHSLValues: RegExp = /^[Hh][Ss][Ll]\\(\\s*(0|[1-9]\\d?|[12]\\d\\d|3[0-5]\\d)\\s*,\\s*((0|[1-9]\\d?|100)%)\\s*,\\s*((0|[1-9]\\d?|100)%)\\s*\\)$/;
  // A color represented by HSL(h, s, l) (e.g., 'hsl(240, 100%, 50%)', 'HSL(55, 80%, 22%)' etc.)
  static ColorByHSLAValues: RegExp = /^[Hh][Ss][Ll][Aa]\\(\\s*(0|[1-9]\\d?|[12]\\d\\d|3[0-5]\\d)\\s*,\\s*((0|[1-9]\\d?|100)%)\\s*,\\s*((0|[1-9]\\d?|100)%)\\s*\\,\\s*((0.[0-9]*)|[01])\\s*)$/;

  /** Regular Expressions that will assist in converting URLs for externally linked images, videos, audio, documents, etc. */
  static ExtractFileIdFromGoogleDriveShareLink: RegExp = /^https?:\/\/drive.google.com\/file\/d\/(.*)\/view/;
  static DownloadableUrlFromGoogleDriveShareLink: RegExp = /^https?:\/\/drive.google.com\/file\/d\/.*\/view/;
  // static ExtractVideoIdFromTiktokCopiedLink: RegExp = /^https?:\/\/www.tiktok.com\/\@(.*)\/video\/d/;
  // static ExtractVideoIdFromTiktokCopiedLink: RegExp = /^https?:\/\/www.tiktok.com\/*\/video\/.*/;
  // static ExtractVideoIdFromTiktokCopiedLink: RegExp = /^https?:\/\/www.tiktok.com\/*\/video\/(\d+)/;
  static ExtractVideoIdFromTiktokCopiedLink: RegExp = /^.*https:\/\/(?:m|www|vm)?\.?tiktok\.com\/((?:.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+))|\w+)/;

}